import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import { customPropTypes, sanitizer, useCSSMotion } from '../../util';
import Logo from '../Logo/Logo';
import GatsbyLink from '../GatsbyLink/GatsbyLink';
import LogoTitle from '../Hero/LogoTitle/LogoTitle';

import './AfterHeroStatement.scss';

function AfterHeroStatement({ data }) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  const { title, linkedArticle } = data;
  const body = linkedArticle.updatePostFields?.body || linkedArticle.blogPostFields?.postBody;

  return (
    <section ref={containerRef} className={classnames('AfterHeroStatement', motionState)}>
      <div className="container">
        <LogoTitle title={title} motionState={motionState} />

        <div className="AfterHeroStatementCard">
          <div className="AfterHeroStatementIcon">
            <Logo />
          </div>
          <h2 className="AfterHeroStatementTitle" dangerouslySetInnerHTML={{ __html: linkedArticle.title }} />
          <div dangerouslySetInnerHTML={{ __html: sanitizer(body, true, {}) }} />
          <GatsbyLink to={linkedArticle.uri} className="AfterHeroStatementCardFooter">
            Read the full statement
          </GatsbyLink>
        </div>
      </div>
    </section>
  );
}

AfterHeroStatement.propTypes = checkExtraProps({
  data: PropTypes.shape({
    title: PropTypes.string,
    linkedArticle: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
      updatePostFields: PropTypes.shape({
        body: PropTypes.string,
      }),
      blogPostFields: PropTypes.shape({
        postBody: PropTypes.string,
      }),
    }),
    ...customPropTypes.basePropTypes,
  }).isRequired,
});

export default memo(AfterHeroStatement);

export const fragments = graphql`
  fragment AfterHeroStatement on WpPage_Flexiblelayout_FlexibleChildren_AfterHeroStatement {
    title
    linkedArticle {
      ... on WpUpdate {
        title
        uri
        updatePostFields {
          body
        }
      }
      ... on WpBlog {
        title
        uri
        blogPostFields {
          postBody
        }
      }
    }
    name
    id
    jumpToLinkTitle
  }

  fragment AfterHeroStatement_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_AfterHeroStatement {
    title
    linkedArticle {
      ... on WpUpdate {
        title
        uri
        updatePostFields {
          body
        }
      }
      ... on WpBlog {
        title
        uri
        blogPostFields {
          postBody
        }
      }
    }
    name
    id
    jumpToLinkTitle
  }
`;
