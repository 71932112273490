import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { customPropTypes } from '../../../util';
import AnimatedLogo from '../../AnimatedLogo/AnimatedLogo';

import './LogoTitle.scss';

function LogoTitle({ title, motionState }) {
  return (
    <div className={classnames('LogoTitle', motionState)}>
      <AnimatedLogo motionState={motionState} />
      <h1 className="title">{title}</h1>
    </div>
  );
}

LogoTitle.propTypes = checkExtraProps({
  title: PropTypes.string,
  motionState: customPropTypes.motionStatePropTypes,
});

LogoTitle.defaultProps = {};

export default memo(LogoTitle);
