import React, { memo } from 'react';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';

import './AnimatedLogo.scss';

import { customPropTypes } from '../../util';

function AnimatedLogo({ motionState }) {
  return (
    <svg
      className={classnames('AnimatedLogo', motionState)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 298.71 301.18"
    >
      <path d="M0 66.1C20.3 24.95 66.37-6 115.49 14.21c18.46 7.6 42.14 26.1 67.05 36.41C222.6 67.21 271.7 53.08 298.71 0" />
      <path d="M0 183.67c20.3-41.15 66.37-72.1 115.49-51.89 18.46 7.6 42.14 26.1 67.05 36.41 40.06 16.59 89.16 2.46 116.17-50.62" />
      <path d="M0 301.18c20.3-41.15 66.37-72.1 115.49-51.89 18.46 7.6 42.14 26.1 67.05 36.41 40.06 16.59 89.16 2.46 116.17-50.62" />
    </svg>
  );
}

AnimatedLogo.propTypes = checkExtraProps({
  motionState: customPropTypes.motionStatePropTypes,
});

AnimatedLogo.defaultProps = {};

export default memo(AnimatedLogo);
